import React from 'react';
import ReactDOM from 'react-dom';
import $ from 'jquery';
import ReactPaginate from 'react-paginate';

class Report extends React.Component {
  render() {
    const body = this.props.data.map((data) => {
      return (
        <tr key={`report-${data.id}`}>
          <td>{data.monitor}</td>
          <td>{data.reviewer}</td>
          <td dangerouslySetInnerHTML={{__html: data.status}}></td>
          <td>{data.category}</td>
          <td>{data.monitoring_date}</td>
          <td>{data.serial_number}</td>
          <td>{data.registration_number}</td>
          <td><a href={data.show_url} className='btn btn-default'>{this.props.buttons[0]}</a></td>
          <td>
            { data.edit_url ? <a href={data.edit_url} className='btn btn-default'>{this.props.buttons[1]}</a> : '' }
          </td>
          <td>
            {(() => {
              if (data.delete_url) {
                return (
                  <a href={data.delete_url} className='btn btn-default'
                     data-confirm={this.props.buttons[3]} data-method="delete">
                    {this.props.buttons[2]}
                  </a>
                );
              }
            })()}
          </td>
          <td>
            {(() => {
              if (data.export_url) {
                return (
                  <a href={data.export_url} className='btn btn-default' target='_blank'>
                    {this.props.buttons[4]}
                  </a>
                );
              }
            })()}
          </td>
        </tr>
      );
    });

    return body;
  }
}

class Paginate extends React.Component {
  onClick(e) {
    $.ajax({
      url: `${$('.reports-area').data('url')}?page=${e.selected + 1}`,
      type: 'get',
      dataType: 'json',
      data: {
        show_date: $('.show-date').val(),
        monitor: $('.select_monitor').val(),
        reviewer: $('.select_reviewer').val(),
        status: $('.select_status').val(),
        category: $('.select_category').val(),
        registration_number: $('.select_registration_number').val()
      }
    }).done((res) => {
      filterDone(res);
    });
  }

  render() {
    return (
      <ReactPaginate previousLabel='&lsaquo;'
                     nextLabel='&rsaquo;'
                     breakLabel={<a href=''>&hellip;</a>}
                     breakClassName={'break-me'}
                     pageCount={this.props.pageCount}
                     marginPagesDisplayed={2}
                     pageRangeDisplayed={5}
                     onPageChange={(e) => { this.onClick(e) }}
                     forcePage={this.props.selected}
                     containerClassName={'pagination'}
                     subContainerClassName={'pages pagination'}
                     activeClassName={'active'} />
    );
  }
}

function filterDone(res) {
  ReactDOM.render(
    <Report data={res.data} buttons={$('.reports-area').data('buttons')} />,
    $('.reports-area')[0]
  );
  ReactDOM.render(
    <Paginate data={res.data} pageCount={Math.ceil(res.total_count / res.limit)} selected={res.selected} />,
    $('.pagination-area')[0]
  );

  if (res.show_paginate) {
    $('.pagination-area').show();
  } else {
    $('.pagination-area').hide();
  }
}

$(() => {
  $('.all-button').click(() => {
    $.ajax({
      url: $('.filter-input').data('url'),
      type: 'get',
      dataType: 'json'
    }).done((res) => {
      $('.show-date').val('');
      $('.report-filter').val('');
      filterDone(res);
    });
  });
  if ($('.all-button').length) {
    $('.all-button').click();
  }

  $('.go-button').click(() => {
    $.ajax({
      url: $('.filter-input').data('url'),
      type: 'get',
      dataType: 'json',
      data: {
        show_date: $('.show-date').val()
      }
    }).done((res) => {
      filterDone(res);
    });
  });

  function startSelect() {
    $.ajax({
      url: $('.reports-area').data('url'),
      type: 'get',
      dataType: 'json',
      data: {
        show_date: $('.show-date').val(),
        monitor: $('.select_monitor').val(),
        reviewer: $('.select_reviewer').val(),
        status: $('.select_status').val(),
        category: $('.select_category').val(),
        registration_number: $('.select_registration_number').val()
      }
    }).done((res) => {
      filterDone(res);
    });
  }
  $('.report-filter').change(() => {
    startSelect();
  });

  if ($('.number-autocomplete').length) {
    $('.number-autocomplete').on('change keyup paste mouseup', (e) => {
      if ($(e.target).val() == '') startSelect();
    });
    $('.number-autocomplete').autocomplete({
      source: $('.registration-numbers').data('value'),
      delay: 200,
      select: (e, ui) => {
        $('.number-autocomplete').first().val(ui.item.value);
        startSelect();
      }
    }).autocomplete('instance')._renderItem = (ul, item) => {
      return $('<li>').attr('data-value', item.value).attr('class', 'p-5')
                      .data('ui-autocomplete-item', item.value).append(`<a>${item.value}</a>`).appendTo(ul);
    };
  }
});
