/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import 'es6-shim';

import $ from 'jquery';
window.$ = window.jQuery = $;

import Rails from 'rails-ujs';
Rails.start();

import 'jquery-ui-bundle';
import 'moment';
import 'bootstrap-sass';
import 'eonasdan-bootstrap-datetimepicker';

import './report';
import './form';

$(() => {
  $('.datepicker').datetimepicker({
    format: 'YYYY/MM/DD',
    icons: {
      previous: 'fa fa-arrow-circle-left',
      next: 'fa fa-arrow-circle-right'
    }
  });
  $('.timepicker').datetimepicker({
    format: 'H:mm',
    icons: {
      up: 'fa fa-arrow-circle-up',
      down: 'fa fa-arrow-circle-down'
    }
  });

  // report index
  function changeDateState() {
    if ($('.show-date').length && $('.show-date').val().length > 0) {
      $('.go-button').prop('disabled', false);
    } else
    {
      $('.go-button').prop('disabled', true);
    }
  };
  $('.show-date').blur(() => {
    changeDateState();
  });
  $('.show-date').keyup(() => {
    changeDateState();
  });
  changeDateState();

  $('.new-report-button').click((e) => {
    e.preventDefault();
    var input = $('<input>').attr('type', 'hidden').attr('name', 'show-date').val($('.show-date').val());
    $('.new-report-form').append($(input));
    $('.new-report-form').submit();
  });

  // report form
  function changeInput() {
    if ($('.report-approach:checked').val() == 'visit') {
      changeState($('.visit-input-form'), false);
      changeState($('.other-input-form'), true);
      changeState($('.time-input-form'), false);
    } else if ($('.report-approach:checked').val() == 'post') {
      changeState($('.visit-input-form'), true);
      changeState($('.other-input-form'), true);
      changeState($('.time-input-form'), true);
    } else if ($('.report-approach:checked').val() == 'other_approach') {
      changeState($('.visit-input-form'), true);
      changeState($('.other-input-form'), false);
      changeState($('.time-input-form'), false);
    } else {
      changeState($('.visit-input-form'), true);
      changeState($('.other-input-form'), true);
      changeState($('.time-input-form'), false);
    }
  };
  function changeState(element, state) {
    element.find('input').prop('disabled', state);
  }
  $('.report-approach').change(() => {
    changeInput();
  });
  changeInput();

  let isChanged = false;
  $('input, select, textarea').change(() => {
    isChanged = true;
  });
  $('.datepicker, .timepicker').on('dp.change', (e) => {
    isChanged = true;
  });
  $('button[type=sbmit]').click(() => {
    isChanged = false;
  });

  $('a').click((e) => {
    if ($('.report-submit-button').length && isChanged && e.currentTarget.className !== 'dropdown-toggle') {
      if (!confirm($('.remove-confirm-text').data('confirm'))) {
        e.preventDefault();
        e.stopPropagation();
      }
    }
  });

  const reportSubmitButton = document.querySelector('.report-submit-button');
  if (reportSubmitButton) {
    const radioButtons = document.querySelectorAll('input[type=radio]');
    const names = new Set(Array.from(radioButtons).map((i) => i.name));
    reportSubmitButton.addEventListener('click', (e) => {
      const unchecked = Array.from(names).map((name) => {
        if (!document.querySelector(`input[name="${name}"]:checked`)) { return name.match(/\d+/)[0]; }
      }).filter(v => v);
      if (unchecked.length) {
        e.preventDefault();
        e.stopPropagation();
        alert(`${reportSubmitButton.dataset.hasBlank}\n${unchecked.join(', ')}`);
      }
    });
  }

  // comments
  $('.comment-form').keyup(() => {
    if ($('.comment-form').val().length > 0) {
      $('.comment-btn').prop('disabled', false);
    } else {
      $('.comment-btn').prop('disabled', true);
    }
  });
})
