import React from 'react';
import ReactDOM from 'react-dom';
import $ from 'jquery';

class Interviewee extends React.Component {
  constructor(props) {
    super(props);

    const state = {
      investigator: { data: [] },
      subinvestigator: { data: [] },
      crc: { data: [] },
      secretariat: { data: [] },
      other: { data: [] }
    }
    let index = 0;
    this.props.roles.forEach((role) => {
      const filteredData = this.props.data.interviewees.filter((interviewee) => { return interviewee.role == role });
      if (filteredData.length) {
        filteredData.forEach((d) => {
          state[role].data.push({ user: d, index: index });
          index++;
        });
      } else {
        state[role].data.push({ user: null, index: index });
        index++;
      }
    });

    this.state = state;
  }

  onClick(e) {
    const state = Object.assign({}, this.state);
    state[$(e.target).data('role')].data.push({ user: null, index: $('.interviewee-form').length });
    this.setState(state);
  }

  render() {
    const forms = this.props.roles.map((role, index) => {
      return (
        <div className='row' key={`role-${role}-container`}>
          <div className='col-md-2 pt-5 text-right'>
            {this.props.labels[index]}
          </div>
          <div className='col-md-4'>
            <div>
              {
                this.state[role].data.map((d) => {
                  return <IntervieweeForm key={`role-form-${d.index}`} index={d.index}
                                          users={this.props.data.institution_users} role={role}
                                          interviewee={d.user} reportId={this.props.data.report_id} />
                })
              }
            </div>
            {(() => {
              if (role != 'investigator') {
                return (
                  <div>
                    <button className='btn btn-default mb-m' type='button'
                            data-role={role} onClick={(e) => { this.onClick(e) }}>
                      {this.props.buttons[index - 1]}
                    </button>
                  </div>
                );
              }
            })()}
          </div>
        </div>
      );
    });

    return forms;
  }
}

class IntervieweeForm extends React.Component {
  render() {
    const select =
      <select name={`report[interviewees_attributes][${this.props.index}][institution_user_id]`}
              className={`form-control mb-xs ${this.props.role}-form`}
              defaultValue={this.props.interviewee ? this.props.interviewee.institution_user_id : ''}>
        <Options includeBlank={true} keyPrefix={`interviewee-form-${this.props.index}-option-`}
                 options={this.props.users.map((user, index) => {
                   return { index: index + 1, text: user.name, value: user.id }
                 })} />
      </select>;

    return (
      <div className='interviewee-form'>
        {select}
        <input type='hidden' value={this.props.reportId ? this.props.reportId : ''}
               name={`report[interviewees_attributes][${this.props.index}][report_id]`} />
        <input type='hidden' value={this.props.role}
               name={`report[interviewees_attributes][${this.props.index}][role]`} />
        <input type='hidden' value={this.props.interviewee ? this.props.interviewee.id : ''}
               name={`report[interviewees_attributes][${this.props.index}][id]`} />
      </div>
    );
  }
}

class Companion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      count: props.data.report_users.length > 2 ? props.data.report_users.length : 2
    };
  }

  onClick(e) {
    this.setState({ count: this.state.count + 1 });
  }

  render() {
    const forms = [];
    for (let i = 0; i < this.state.count; i++) {
      forms.push(
        <CompanionForm key={`companion-form-${i}`}
                       users={this.props.data.users} reportUser={this.props.data.report_users[i]}
                       index={i} reportId={this.props.data.report_id} />
      );
    }

    return (
      <div>
        <div>
          {forms}
        </div>
        <div>
          <button className='btn btn-default mb-m' type='button' onClick={(e) => { this.onClick(e) }} >
            {this.props.button}
          </button>
        </div>
      </div>
    );
  }
}

class CompanionForm extends React.Component {
  render() {
    const select =
      <select name={`report[report_users_attributes][${this.props.index}][user_id]`}
              className='form-control mb-xs'
              defaultValue={this.props.reportUser ? this.props.reportUser.user_id : ''}>
        <Options includeBlank={true} keyPrefix={`companion-form-${this.props.index}-option-`}
                 options={this.props.users.map((user, index) => {
                   return { index: index + 1, text: user.name, value: user.id }
                 })} />
      </select>;

    return (
      <div className='companion-form'>
        {select}
        <input type='hidden' value={this.props.reportId ? this.props.reportId : ''}
               name={`report[report_users_attributes][${this.props.index}][report_id]`} />
             <input type='hidden' value={this.props.reportUser ? this.props.reportUser.id : ''}
               name={`report[report_users_attributes][${this.props.index}][id]`} />
      </div>
    );
  }
}

class Options extends React.Component {
  render() {
    const options = this.props.includeBlank ? [<option key={`${this.props.keyPrefix}0`} />] : [];
    options.push(this.props.options.map((option) => {
      return <option key={`${this.props.keyPrefix}${option.index}`} value={option.value}>{option.text}</option>;
    }));
    return options;
  }
}

$(() => {
  if ($('.companions-container').length) {
    $.ajax({
      type: 'get',
      dataType: 'json',
      data: {
        report_id: $('.companions-container').data('report-id'),
        trial_id: $('.companions-container').data('trial-id')
      },
      url: $('.companions-container').data('url')
    }).done((res) => {
      ReactDOM.render(
        <Companion data={res} button={$('.companions-container').data('button')} />,
        $('.companions-container')[0]
      );
    });
  }

  if ($('.interviewees-container').length) {
    $.ajax({
      type: 'get',
      dataType: 'json',
      data: {
        report_id: $('.interviewees-container').data('report-id'),
        institution_id: $('.interviewees-container').data('institution-id')
      },
      url: $('.interviewees-container').data('url')
    }).done((res) => {
      ReactDOM.render(
        <Interviewee data={res} labels={$('.interviewees-container').data('labels')}
                     roles={$('.interviewees-container').data('roles')}
                     buttons={$('.interviewees-container').data('buttons')} />,
        $('.interviewees-container')[0]
      );
    });
  }
});
